import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import RouteX from 'src/components/route-x';
import PrivateRoute from 'src/components/private-route';

import DashboardLayout from 'src/layouts/dashboard';
import LoginPage from "src/pages/Login/";
import ResetPasswordPage from 'src/pages/Reset-Password/';

import { ROUTE_PATH, PROTECTED_ROUTES } from 'src/utils/data-util';

export default function Routes() {

    return (
        <Switch>
            <RouteX path="/login" component={LoginPage} redirect="/dashboard" />
            <Route path={ROUTE_PATH.resetPW}>
                <ResetPasswordPage />
            </Route>
            <PrivateRoute path="/dashboard/:path*">
                <DashboardLayout>
                    <Switch>
                        {
                            PROTECTED_ROUTES.map((route, index) => (
                                <Route {...route} key={index}>
                                    {route.redirect && <Redirect to={route.redirect} />}
                                </Route>
                            ))
                        }
                    </Switch>
                </DashboardLayout>
            </PrivateRoute>
            <Route exact path={ROUTE_PATH.root}>
                <Redirect to={ROUTE_PATH.login} />
            </Route>
        </Switch>
    );
}
