import { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { useForm } from "react-hook-form";

import PaperContainer from "src/components/paper-container-with-title";
import ClientPaper from "src/components/client-paper";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    marginBottom: 60,
    display: "flex",
  },
  searchBox: {
    width: "35%",
  },
}));

const Client = () => {
  const classes = useStyles();
  const { register, setValue, handleSubmit } = useForm({});
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/client`
        );
        console.log("client", res.data.clients);
        setClients(res.data.clients);
      } catch (err) {
        console.log("failed-to-get-client", err);
      }
    };

    register("search");
    fetchClient();
  }, [register]);

  const onSubmit = async (data) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/client?search=${data.search}`
      );
      setClients(res.data.clients);
    } catch (err) {
      console.log("failed-to-search-client", err);
    }
  };

  const handleDelete = async (clientId, index) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/client`, {
        data: { clientId },
      });
      console.log(index);
      setClients((clients) => {
        const afterDeleted = [...clients];
        afterDeleted.splice(index, 1);
        return afterDeleted;
      });
    } catch (err) {
      console.log("failed-to-delete-client", err);
    }
  };

  return (
    <Grid container className={classes.root}>
      <PaperContainer title="List of Clients">
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid xs={12} container justifyContent="flex-end">
            <Autocomplete
              freeSolo
              className={classes.searchBox}
              disableClearable
              options={["Candidate Name", "Chea Sowattana", "Michael Devel"]}
              onChange={(e, options) => setValue("search", options)}
              onInputChange={(e, options) => setValue("search", options)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search for Client"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <InputAdornment>
                        <IconButton type="submit">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </form>
      </PaperContainer>
      <Grid item xs={12}>
        {clients.map((client, key) => (
          <ClientPaper {...client} handleDelete={handleDelete} inputKey={key} />
        ))}
      </Grid>
    </Grid>
  );
};

export default Client;
