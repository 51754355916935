import { Button, makeStyles, Typography } from "@material-ui/core";

const TitleButton = ({ caption, startIcon, endIcon, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={classes.button}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <Typography className={classes.typo}>{caption}</Typography>
    </Button>
  );
};

const useStyles = makeStyles((themes) => ({
  button: {
    color: themes.palette.primary.main,
    border: `1px solid ${themes.palette.primary.main}`,
    borderRadius: 10,
    padding: "2.5px 10px",
    marginLeft: 10,
  },
  typo: {
    fontSize: 10,
  },
}));

export default TitleButton;
