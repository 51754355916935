import { Button, Grid, Typography, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import ProfilePicture from "src/components/profile-picture";

const ContactList = ({ contacts, handleRemove, handleEdit }) => {
  const generateContacts = () => {
    return contacts.map((item, index) => {
      if (item.isDeleted) return null
      return (
        <Contact
          key={item.id}
          firstname={item.firstname}
          lastname={item.lastname}
          position={item.position}
          phone={item.phone}
          email={item.email}
          handleRemove={() => handleRemove(index, item)}
          handleEdit={() => handleEdit(index)}
        />
      );
    });
  };

  return (
    <Grid container spacing={3}>
      {generateContacts()}
    </Grid>
  );
};

const Contact = ({
  firstname,
  lastname,
  position,
  phone,
  email,
  handleEdit,
  handleRemove,
}) => (
  <Grid item xs={4}>
    <Grid container alignItems="center" alignContent="center" spacing={2}>
      <Grid item>
        <ProfilePicture
          src={null}
          avatarSize={75}
          handleRemove={handleRemove}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body" style={{ fontWeight: "bold" }}>
              {firstname} {lastname}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body">{position || "N/A"}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body">{phone}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body">{email}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton onClick={() => handleEdit()} aria-label="edit">
          <Edit />
        </IconButton>
      </Grid>
    </Grid>
  </Grid>
);

// const useStyles = makeStyles((themes) => ({
//   contact: {
//     alignContent: "middle",
//   },
// }));

export default ContactList;
