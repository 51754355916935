import { Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const CustomButton = ({ caption, customVariant = "primary", ...props }) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx({
        [classes.button]: true,
        [classes.primaryButton]: customVariant === "primary",
        [classes.dangerButton]: customVariant === "danger",
        [classes.warningButton]: customVariant === "warning",
      })}
      {...props}
    >
      <Typography>{caption}</Typography>
    </Button>
  );
};

const useStyles = makeStyles((themes) => ({
  button: {
    padding: "0.4em 1em",
    width: "100%",
  },
  primaryButton: {
    backgroundColor: themes.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: themes.palette.secondary.main,
    },
  },
  dangerButton: {
    backgroundColor: themes.palette.primary.danger,
    color: "white",
    "&:hover": {
      backgroundColor: themes.palette.secondary.danger,
    },
  },
  warningButton: {
    backgroundColor: themes.palette.primary.warning,
    color: "black",
    "&:hover": {
      backgroundColor: themes.palette.secondary.warning,
    },
  },
}));

export default CustomButton;
