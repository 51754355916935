import React, { useEffect } from 'react';
import {
    Box,
    Toolbar,
    Paper,
    Grid,
} from '@material-ui/core';
import numeral from 'numeral';
import { useDispatch, useSelector } from "react-redux";
import { getDashboardDataRequest } from "src/redux/dashboard/action";
import { dashboardSelector } from "src/redux/dashboard/selector";

import useStyles from "./style";

export default function Dashboard(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { dashboardData } = useSelector(dashboardSelector);

    useEffect(() => {
        dispatch(getDashboardDataRequest({}));
    }, [dispatch]);

    return (
        <div className={classes.root}>
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper} >
                            <Box fontSize={30} mb={2}>
                                Clients
                            </Box>
                            <Box fontSize={50} textAlign='right'>
                                {dashboardData.data &&
                                    numeral(dashboardData.data[0].client).format('0,0')
                                }
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper} >
                            <Box fontSize={30} mb={2}>
                                Candidates
                            </Box>
                            <Box fontSize={50} textAlign='right'>
                                {dashboardData.data &&
                                    numeral(dashboardData.data[0].candidate).format('0,0')
                                }
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div>
    );
}
