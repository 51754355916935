import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  typography: {
    h1: {
      fontSize: 30,
      fontWeight: "bold",
      margin: "0.5em 0.25em",
    },
    h3: {
      fontSize: 18,
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
      fontSize: 16,
    },
  },
  palette: {
    primary: {
      main: "#0096d6",
      danger: "#d32121",
      warning: "#eed202",
      contrastText: "#fff",
      chip: "#dddddd",
    },
    secondary: {
      main: "#0072a4",
      danger: "#b10f0f",
      warning: "#ffe087",
      contrastText: "#fff",
    },
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: false, // No more ripple, on the whole application 💣!
    },
  },
});
