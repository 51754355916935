import { Grid, Paper, makeStyles, Button, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary,
    fontSize: "1.5em",
    fontWeight: "bold",
  },
  paperContainer: {
    boxShadow: "0 0 4px #bbbbbb !important",
    padding: "1em 3em",
    marginBottom: 15,
    "&:hover": {
      boxShadow: "0 0 15px #bbbbbb !important",
    },
  },
  contactInfo: {
    listStyle: "none",
    "& li": {
      padding: 5,
    },
  },
}));

const ClientPaper = ({
  _id,
  name,
  type: clientType,
  address,
  phone,
  website,
  handleDelete,
  inputKey,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={classes.paperContainer} key={inputKey}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} container justifyContent="flex-end">
          <IconButton
            color="primary"
            onClick={() => handleDelete(_id, inputKey)}
          >
            <Delete />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} md={6} className={classes.title}>
            <b>{name}</b>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {/* <Grid item xs={12} md={6}>
            <b>Primary Contact</b>
            <ul className={classes.contactInfo}>
              <li>{`${contact[0].firstname} ${contact[0].lastname}`}</li>
              <li>{contact[0].email || "N/A"}</li>
              <li>{contact[0].phone || "N/A"}</li>
            </ul>
          </Grid> */}
            <Grid item xs={12} md={6}>
              <b>Client Information</b>
              <ul className={classes.contactInfo}>
                <li>{clientType || "N/A"}</li>
                <li>{address || "N/A"}</li>
                <li>{phone || "N/A"}</li>
                <li>{website || "N/A"}</li>
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12} justifyContent="flex-end">
            <Button onClick={() => history.push(`/dashboard/client/${_id}`)}>
              View Detail
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClientPaper;
