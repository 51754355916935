import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";

import ClientForm from "./client-form";
import ContactList from "./contact-list";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import PaperContainerWithTitle from "src/components/paper-container-with-title";
import TitleButton from "src/components/Button/title-button";
import ControlledTextField from "src/components/controlled-components/controlled-text-field";
import Client from "src/api/client";

const CreateClient = () => {
  const classes = useStyles();
  const history = useHistory();
  const methods = useForm({
    defaultValues: {
      client: {
        name: '',
        type: '',
        phone: '',
        website: '',
        address: ''
      }
    },
  });
  const { clientId } = useParams();
  const contactForm = useForm({
    defaultValues: {},
  });

  const {
    fields,
    append: appendContact,
    remove: removeContact,
    update: updateContact,
  } = useFieldArray({
    control: methods.control,
    name: "client.contacts",
  });

  const handleRemoveContact = (index, data) => {
    if (data._id) {
      updateContact(index, { ...data, isDeleted: true })
    } else {
      removeContact(index)
    }
    // data._id existed update isDeleted true
    // remove by index
  }

  const [openCandidateDialog, setOpenCandidateDialog] = useState(false);
  const [editingContactIndex, setEditingContactIndex] = useState(null);

  const onSubmit = async (data) => {
    try {
      if (!clientId) {
        const res = await Client.create(data.client);
        console.log("create client");
        history.push("/dashboard/client");
      } else {
        const res = await Client.update(data.client);
        console.log("update client")
      }

    } catch (err) {
      console.log("failed-to-create-client", err);
    }
  };

  const handleCreateContact = (data) => {
    if (editingContactIndex !== null) {
      updateContact(editingContactIndex, data);
    } else {
      appendContact({ ...data });
    }

    setOpenCandidateDialog(false);
  };

  const handleEditContact = (index) => {
    contactForm.reset(fields[index]);
    setOpenCandidateDialog(true);
    setEditingContactIndex(index);
  };

  useEffect(() => {
    const fetchClientInfo = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/client/${clientId}`
      );
      methods.reset({
        client: {
          ...res.data.clients
        }
      })
    }
    clientId && fetchClientInfo();
  }, [clientId, methods])

  const CandidateDialog = () => (
    <Dialog
      open={openCandidateDialog}
      onClose={() => setOpenCandidateDialog(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Create New Contact</DialogTitle>
      <form onSubmit={contactForm.handleSubmit(handleCreateContact)}>
        <DialogContent>
          <Grid container direction="column" spacing={5}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ControlledTextField
                    autoFocus
                    placeholder="First Name"
                    fullWidth
                    control={contactForm.control}
                    name="firstname"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlledTextField
                    placeholder="Last Name"
                    fullWidth
                    control={contactForm.control}
                    name="lastname"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ControlledTextField
                placeholder="Position"
                fullWidth
                control={contactForm.control}
                name="position"
              />
            </Grid>
            <Grid item>
              <ControlledTextField
                placeholder="Phone Number"
                fullWidth
                control={contactForm.control}
                name="phone"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item>
              <ControlledTextField
                placeholder="E-mail"
                fullWidth
                control={contactForm.control}
                name="email"
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCandidateDialog(false)} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Add Contact
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <>
      <FormProvider {...methods}>
        <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container className={classes.root}>
            <PaperContainerWithTitle title={(clientId) ? 'View Profile' : 'New Client'}>
              <ClientForm />
            </PaperContainerWithTitle>
            <PaperContainerWithTitle
              title="Client Contact"
              titleButton={
                <TitleButton
                  onClick={() => {
                    contactForm.reset({});
                    setEditingContactIndex(null);
                    setOpenCandidateDialog(true);
                  }}
                  caption="Create New Contact"
                  startIcon={<AddIcon />}
                />
              }
            >
              <ContactList
                contacts={fields}
                handleRemove={handleRemoveContact}
                handleEdit={handleEditContact}
              />
            </PaperContainerWithTitle>
            <Box className={classes.submit}>
              <Box margin={2} marginRight={8}>
                <Button className={classes.buttonDanger}>Cancel</Button>
                <Button type="submit" className={classes.buttonPrimary}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </form>
      </FormProvider>
      <CandidateDialog />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  submit: {
    backgroundColor: "rgba(111, 111, 111, 0.3)",
    position: "fixed",
    width: "100vw",
    bottom: 0,
    left: 0,
    textAlign: "right",
  },
  buttonPrimary: {
    backgroundColor: "#484848",
    color: "white",
    borderRadius: 5,
    margin: "0.25em 0.4em",
    padding: "0.75em 2em",
    "&:hover": {
      backgroundColor: "#262626",
    },
  },
  buttonDanger: {
    backgroundColor: "#D32121",
    color: "white",
    borderRadius: 5,
    margin: "0.25em 0.4em",
    padding: "0.75em 2em",
    "&:hover": {
      backgroundColor: "#C21010",
    },
  },
}));

export default CreateClient;
