import TextField from "@material-ui/core/TextField";
import { useController } from "react-hook-form";

const ControlledTextField = ({
  control,
  name,
  rules,
  defaultValue = "",
  placeholder,
  ...props
}) => {
  const {
    field: { onChange, onBlur, name: componentName, value, ref },
    // fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <TextField
      {...props}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      name={componentName}
      control={control}
      error={errors[componentName]}
      inputRef={ref}
    />
  );
};

export default ControlledTextField;
