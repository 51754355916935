// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
export const top100Jobs = [
  { title: 'IT Specialist', year: 1994 },
  { title: 'Senior Programmer', year: 1972 },
  { title: 'Digital Marketing Manager', year: 1974 },
  { title: 'Store Manager', year: 2008 },
  { title: 'Recruitment Manager', year: 1957 },
  { title: 'Physician', year: 1993 },
  { title: 'Net Idols', year: 1994 },
  { title: 'Junior Programmer', year: 2003 },
  { title: 'DevOps Engineers', year: 1966 },
  { title: 'Psycologist', year: 1999 },
];