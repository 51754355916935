import axios from "axios";
import compressImage from "src/utils/image-compress";
import { nanoid } from "nanoid";

const generateKey = () => {
  return `${nanoid(5)}_${new Date().getTime()}`;
};

const s3FileUploading = async (
  folderName,
  uploadingFiles,
  options = {
    thumbnail: false,
  }
) => {
  console.log("uploading", uploadingFiles);
  if (!uploadingFiles || uploadingFiles?.length < 1) return;
  const results = [];
  const uploadObject = {
    Keys: [],
    files: {},
  };

  if (!uploadingFiles.length) {
    uploadingFiles = [uploadingFiles];
  }

  await Promise.all(
    uploadingFiles.map(async (uploadingFile) => {
      if (uploadingFile.Key) {
        results.push(uploadingFile);
        return;
      }

      // Generate the image Key
      const contentType = uploadingFile.type;
      console.log(uploadingFile);
      const fileExtension = uploadingFile.path
        ? uploadingFile.path.split(".").pop()
        : contentType.split("/").pop();

      const generatedKey = generateKey();
      const key = `${folderName}/${generatedKey}`;
      const fileName = `${key}.${fileExtension}`;

      const fileKey = {
        Key: fileName,
        ContentType: contentType,
      };

      let fileObject = {
        file: uploadingFile,
      };

      const result = {
        name: fileKey.Key.split("/").pop(),
        Key: `${fileKey.Key}`,
        mimeType: contentType,
      };

      // if thumbnail is true, generate the thumbnail
      if (options.thumbnail) {
        const thumbnailKey = `${key}-thumbnail.${fileExtension}`;
        const { image, thumbnail } = await compressImage(uploadingFile);

        fileKey.thumbnailKey = thumbnailKey;
        fileObject = {
          file: image,
          thumbnail: thumbnail,
        };
        result.thumbnail = thumbnailKey;
      }

      uploadObject.Keys.push(fileKey);
      uploadObject.files[fileName] = fileObject;

      results.push(result);

      return true;
    })
  );

  if (uploadObject.Keys.length > 0) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/aws-s3/file-upload-url`,
      {
        Keys: uploadObject.Keys,
        thumbnail: options.thumbnail,
      }
    );

    // Uploading to s3
    await Promise.all(
      response.data.uploadURLArray.map(async (item) => {
        await axios.put(item.uploadURL, uploadObject.files[item.Key].file, {
          headers: {
            "Content-Type": item.type,
            "Content-Encoding": "base64",
          },
          withCredentials: false,
        });
      })
    );
  }

  return results;
};

export default s3FileUploading;
