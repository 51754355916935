import { Grid, TextField } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";

const ClientForm = () => {
  const { register } = useFormContext();

  return (
    <>
      <Grid container xs={12} spacing={5}>
        <Grid item xs={6}>
          <Controller
            name="client.name"
            render={({ field }) => {
              return <TextField
                error={false}
                label="Client Name"
                {...field}
                // helperText="Incorrect entry."
                fullWidth
              />
            }}>
          </Controller>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="client.type"
            render={({ field }) => {
              return <TextField
                error={false}
                label="Client Type"
                {...field}
                // helperText="Incorrect entry."
                fullWidth
              />
            }}>
          </Controller>
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={5}>
        <Grid item xs={6}>
          <Controller
            name="client.phone"
            render={({ field }) => {
              return <TextField
                error={false}
                label="Client Number"
                {...field}
                // helperText="Incorrect entry."
                fullWidth
              />
            }}>
          </Controller>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="client.website"
            render={({ field }) => {
              return <TextField
                error={false}
                label="Client website"
                {...field}
                // helperText="Incorrect entry."
                fullWidth
              />
            }}>
          </Controller>
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={5}>
        <Grid item xs={6}>
          <Controller
            name="client.address"
            render={({ field }) => {
              return <TextField
                error={false}
                label="Client Address"
                {...field}
                // helperText="Incorrect entry."
                fullWidth
              />
            }}>
          </Controller>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </>
  );
};

export default ClientForm;
