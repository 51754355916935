import axios from "axios";

const Client = {
  create: async (data) =>
    axios.post(`${process.env.REACT_APP_API_URL}/api/v1/client`, data, {
      withCredentials: true,
    }),
  update: (data) =>
    axios.put(`${process.env.REACT_APP_API_URL}/api/v1/client/${data._id}`, data, {
      withCredentials: true,
    }),
};

export default Client;
